import React, { useEffect } from "react"
import { useField } from "formik"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Icon from "@components/ui/Icon/Icon"
import { useId } from "@components/hooks/useId"
import Text from "@components/ui/Typography/Text"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import { useController, useStore } from "@store"

import styles from "./SearchInputField.module.sass"

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
}

export const SearchInputField: React.FC<Props> = observer(
  ({ id, name, className, ...rest }) => {
    const [filed, _, meta] = useField(name)
    const htmlId = useId("input_")
    const { solutionsStore, restrictionsStore: access } = useStore()
    const { appliedFilters } = solutionsStore
    const { solutionsController } = useController()
    const searchFilterModal = useModal(ModalsTypes.SEARCH_FILTERS_MODAL)
    const { hideModal } = useModal(ModalsTypes.SEARCH_FILTERS_MODAL)
    const showFilterModal = () => {
      searchFilterModal.showModal({})
      solutionsController.resetFiltersToApply()
    }

    useEffect(() => {
      const handleUrlChange = () => {
        hideModal()
      }

      window.addEventListener("popstate", handleUrlChange)
      window.addEventListener("pushState", handleUrlChange)
      window.addEventListener("replaceState", handleUrlChange)

      return () => {
        window.removeEventListener("popstate", handleUrlChange)
        window.removeEventListener("pushState", handleUrlChange)
        window.removeEventListener("replaceState", handleUrlChange)
      }
    }, [searchFilterModal])

    return (
      <label htmlFor={id ?? htmlId} className={styles.input}>
        {access.isSearchFilterAvailable ? (
          <div
            className={styles.filtersWrapper}
            tabIndex={0}
            role="button"
            onKeyPress={() => {}}
            onClick={showFilterModal}
          >
            <div className={styles.filters}>
              <Icon name="funnel" />
              <Text>Filters</Text>
            </div>

            <div className={styles.filtersInfo}>
              <Text variant="h6">
                {appliedFilters.length
                  ? `${appliedFilters.length} filters applied`
                  : "All Products & Data"}
              </Text>
              <Icon name="line-arrow-down" />
            </div>
          </div>
        ) : null}

        <input
          {...rest}
          className={clsx(styles.inputField, className)}
          onChange={(e) => meta.setValue(e.target.value)}
          value={filed.value}
          id={id ?? htmlId}
        />
      </label>
    )
  }
)

export default SearchInputField
