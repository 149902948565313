import { makeAutoObservable } from "mobx"

import MatrixStore from "@components/ui/Spreadsheet/state/MatrixStore"
import { Company, Filename } from "@framework/types/company"

import SimpleCollectionStore from "./simple-entity-collection.store"

const WORKBOOK_SESSION_KEY = "WORKBOOK_SESSION"

export default class UnifiedMatrixStore {
  instance: MatrixStore | null

  filesStore: SimpleCollectionStore<Filename>

  localSnapshot: string | null

  isLoading: boolean = false

  error: string | null = null

  constructor() {
    this.instance = null
    this.filesStore = new SimpleCollectionStore()

    this.localSnapshot = localStorage.getItem(WORKBOOK_SESSION_KEY)

    makeAutoObservable(this)
  }

  get matrixStore(): MatrixStore | null {
    return this.instance
  }

  get isLocalSessionExist() {
    return this.localSnapshot != null
  }

  saveSnapshot = (snapshot: any) => {
    this.localSnapshot = JSON.stringify(snapshot)
    localStorage.setItem(WORKBOOK_SESSION_KEY, this.localSnapshot)
  }

  dropSnapshot = () => {
    this.localSnapshot = null
    localStorage.removeItem(WORKBOOK_SESSION_KEY)
  }
}
