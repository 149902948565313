import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import Spreadsheet from "@components/ui/Spreadsheet/Spreadsheet"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"
import List from "@components/ui/List/List"
import Icon from "@components/ui/Icon/Icon"
import Button from "@components/ui/Button/Button"
import { useController, useStore } from "@store"
import { SolutionData } from "@framework/types/solution"
import { rangeToCode, totalRangeCells } from "@components/ui/Spreadsheet/utils"
import { IContextMenuBuilder } from "@components/ui/Spreadsheet/state/ContextMenuManager"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import MatrixStore from "@components/ui/Spreadsheet/state/MatrixStore"
import mainRoutes from "@root/main.routes"

import styles from "./index.module.sass"

interface Props {
  data: SolutionData
}

const UnifiedMatrix: React.FC<Props> = observer(({ data }) => {
  const navigate = useNavigate()

  const conditionModal = useModal(ModalsTypes.CONFIRM_MODAL)

  const { unifiedMatrixStore } = useStore()

  const { unifiedMatrixController } = useController()

  const { matrixStore } = unifiedMatrixStore

  const initNewInstance = async () => {
    unifiedMatrixStore.dropSnapshot()

    const matrixStore = await unifiedMatrixController.initNewInstance(true)

    if (matrixStore) initCustomFunctions(matrixStore)
  }

  const initInstanceFromSnapshot = async () => {
    const matrixStore = await unifiedMatrixController.initNewInstance(false)

    if (matrixStore) initCustomFunctions(matrixStore)
  }

  const goHome = () => {
    navigate(mainRoutes.home())
  }

  const initCustomFunctions = (matrixStore: MatrixStore) => {
    matrixStore.editManager.functionManager.registerFunction({
      name: "THEME",
      description:
        "Generate themes for a Company by Category and Business Unit",
      arguments: [
        { displayName: "Company" },
        { displayName: "Category" },
        { displayName: "Business Unit", optional: true },
      ],
      transformArgs: (args: any[]) => {
        if (args.length > 3)
          throw new Error("Function THEME expects three arguments maximum")

        if (args.length < 2)
          throw new Error("Function THEME expects at least two arguments")

        if (typeof args[0] !== "string" || !args[0])
          throw new Error(
            "Function THEME expects a string for the Company argument"
          )

        if (typeof args[1] !== "string" || !args[1])
          throw new Error(
            "Function THEME expects a string for the Category argument"
          )

        if (!(args[2] == null || (typeof args[2] === "string" && !!args[2])))
          throw new Error(
            "Function THEME expects a string for the Business Unit argument"
          )

        return args
      },
      handler: async (...args: [string, string, string | undefined]) => {
        const response = await unifiedMatrixController.generateThemes(...args)

        if (response.status === "FAILED")
          throw new Error("Failed to generate themes.")

        return response.data
      },
    })

    matrixStore.editManager.functionManager.registerFunction({
      name: "DOCUMENTCOMPLIANCE",
      description:
        "Generate compliance report for a parent (governing) and child (governed) document.",
      arguments: [
        { displayName: "Parent Document" },
        { displayName: "Child Document" },
      ],
      transformArgs: (args: any[]) => {
        if (args.length !== 2)
          throw new Error(
            "Function DOCUMENTCOMPLIANCE expects tree arguments maximum"
          )

        if (typeof args[0] !== "string" || !args[0])
          throw new Error(
            "Function DOCUMENTCOMPLIANCE expects a string for the Parent Document argument"
          )

        if (typeof args[1] !== "string" || !args[1])
          throw new Error(
            "Function DOCUMENTCOMPLIANCE expects a string for the Child Document argument"
          )

        return args
      },
      handler: async (...args: [string, string]) => {
        const response =
          await unifiedMatrixController.generateDocumentComplianceReport(
            ...args
          )

        if (response.status === "FAILED")
          throw new Error("Failed to generate document compliance report.")

        return response.data
      },
    })
  }

  React.useEffect(() => {
    if (matrixStore != null && unifiedMatrixStore.error == null) {
      const cellMenuBuilder: IContextMenuBuilder = (context) => ({
        groups: [
          {
            name: "FORMAT",
            options: [
              {
                name: "DROPDOWN_FILE",
                value: "Filename",
                icon: "dropdown-list",
              },
              // ...other options
            ],
          },
        ],
        handler: (it) => {
          switch (it.name) {
            case "DROPDOWN_FILE": {
              context.applyValidationRule(
                rangeToCode(context.selectedRange.range),
                "DROPDOWN_FILE"
              )
              if (totalRangeCells(context.selectedRange.range) === 1)
                context.editManager.editCell(context.selectedRange.range.start)
              break
            }

            // ...other option handler cases

            default:
              return false
          }

          return true
        },
      })

      matrixStore.addCellContextMenuBuilder(cellMenuBuilder)
      return () => matrixStore.removeCellContextMenuBuilder(cellMenuBuilder)
    }

    return undefined
  }, [matrixStore])

  React.useEffect(() => {
    if (unifiedMatrixStore.matrixStore != null) return

    if (unifiedMatrixStore.isLocalSessionExist) {
      conditionModal.showModal({
        message: "Would you like to continue previous session?",
        confirmButtonText: "Continue",
        rejectButtonText: "New Session",
        onReject: initNewInstance,
        onConfirm: initInstanceFromSnapshot,
        onClose: goHome,
      })
      return
    }

    initNewInstance()
  }, [])

  return (
    <div className={styles.root}>
      <Templates.Header
        className={styles.header}
        left={
          <List overflow="initial" gutter="0">
            <Text color="text50Color" variant="caption2">
              Workbook
            </Text>
            <Text color="text100Color" variant="h3">
              {data.name}
            </Text>
          </List>
        }
      />
      <div className={styles.body}>
        {matrixStore == null ? (
          unifiedMatrixStore.error == null ? (
            <Loader size="large" fluid />
          ) : (
            <NotFound>{unifiedMatrixStore.error}</NotFound>
          )
        ) : (
          <Spreadsheet
            instance={matrixStore}
            onUpdate={unifiedMatrixStore.saveSnapshot}
          />
        )}
      </div>
    </div>
  )
})

export default UnifiedMatrix
