import React, { useEffect } from "react"
import { Outlet, Route, Routes, useNavigate, useParams } from "react-router-dom"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import { useController, useStore } from "@store/index"
import Templates from "@components/ui/Templates"
import { SolutionTypes } from "@framework/constants/search-results"
import NotFound from "@components/ui/NotFound/NotFound"
import { WorkFlowType } from "@framework/types/solution"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import mainRoutes from "@root/main.routes"

import FactFinderPage from "./SolutionTabsContainer/components/Tabs/FactFinder"
import DocChat from "./SolutionTabsContainer/components/Tabs/DocChat"
import ProductRecommender from "./SolutionTabsContainer/components/Tabs/ProductRecommender"
import ProductComparison from "./SolutionTabsContainer/components/Tabs/ProductComparison"
import UnifiedMatrix from "./SolutionTabsContainer/components/Tabs/UnifiedMatrix"

import styles from "./LaunchSolutions.module.sass"

const LaunchSolutions: React.FC = observer(() => {
  const { solutionType } = useParams()
  const navigate = useNavigate()
  if (solutionType == null) throw new Error("'solutionType' is required param")

  const {
    factFinderSolutionStore: { searchFlowStore },
    solutionsStore,
  } = useStore()
  const {
    appConfigController: { loadAllInstanceConfigurations },
  } = useController()

  useEffect(() => {
    document.documentElement.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "auto",
    })
  }, [])

  useEffect(() => {
    document.documentElement.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    })
  }, [searchFlowStore.flowSequence.length])

  useEffect(() => {
    loadAllInstanceConfigurations()
  }, [])

  const activeSolution = solutionsStore.solution

  const renderSolution = () => {
    if (!activeSolution) return <></>
    switch (solutionType) {
      case WorkFlowType.SEARCH:
        return <FactFinderPage data={activeSolution} />

      case SolutionTypes.PrivateWorkspace:
        return <DocChat data={activeSolution} />

      case WorkFlowType.WORKBOOK:
        return <UnifiedMatrix data={activeSolution} />

      case WorkFlowType.PRODUCT_COMPARISON:
      case SolutionTypes.ProductComparison:
        return (
          <Routes>
            <Route
              path="/"
              element={<ProductComparison data={activeSolution} />}
            >
              <Route
                path=":step"
                element={<ProductComparison data={activeSolution} />}
              />
            </Route>
          </Routes>
        )

      case SolutionTypes.ProductRecommender:
        return <ProductRecommender />

      default:
        return <NotFound>Solution is not available</NotFound>
    }
  }

  const handleBackClick = () => {
    navigate(mainRoutes.home())
  }

  const handleRecentActivity = () => {
    solutionsStore.showRecentActivity = true
  }

  const renderHeaderOptions = () => {
    if (!activeSolution) return <></>
    switch (solutionType) {
      case WorkFlowType.SEARCH:
        return (
          <>
            {!!searchFlowStore?.flowSequence?.length && (
              <Button
                size="medium"
                color="primary"
                disabled={searchFlowStore?.isAnyLoading()}
                onClick={searchFlowStore.reset}
                before={<Icon name="plus" />}
              >
                Start new Chat
              </Button>
            )}
            <Button
              size="medium"
              color="secondary"
              onClick={handleRecentActivity}
              before={<Icon name="retry" />}
            >
              Recent Activity
            </Button>
          </>
        )

      default:
        return null
    }
  }

  return (
    <MainLayout>
      <div className={clsx(styles.root)}>
        <Templates.Header
          className={styles.header}
          left={
            <Button
              before={<Icon name="line-arrow-down" rotateAngle={90} />}
              onClick={handleBackClick}
              variant="text"
              size="small"
            >
              {activeSolution?.name}
            </Button>
          }
          right={renderHeaderOptions()}
        />

        <div className={styles.body}>{renderSolution()}</div>
        <Outlet />
      </div>
    </MainLayout>
  )
})

export default LaunchSolutions
